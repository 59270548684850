var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":"Партнеры","filter":"","create":"","create-title":"Создать партнера","url-create":"/partner/create","url-update":"/partner/update/:id","store-module":"partner","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Название', value: 'name' },
        { text: 'E-mail', value: 'email', width: 1 },
        { text: 'ИНН', value: 'inn', width: 1 },
        { text: 'Контактное лицо (ФИО)', value: 'contacts' },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
    var filter = ref.filter;
return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Нет',
                    value: false
                  },{
                    text: 'Да',
                    value: true
                  }],"label":"Активность"},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ID","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.id),callback:function ($$v) {_vm.$set(filter, "id", $$v)},expression:"filter.id"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Название","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"E-mail","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.email),callback:function ($$v) {_vm.$set(filter, "email", $$v)},expression:"filter.email"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ИНН","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.inn),callback:function ($$v) {_vm.$set(filter, "inn", $$v)},expression:"filter.inn"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Контактное лицо (ФИО)","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.contacts),callback:function ($$v) {_vm.$set(filter, "contacts", $$v)},expression:"filter.contacts"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }